<template>
	<div class="flex gap-1">
		<img
			v-for="n in 5"
			:key="n"
			:class="starSize"
			:src="
				n <= Math.floor(rating)
					? icons['full'].src
					: n - 0.5 <= rating
						? icons['half'].src
						: icons['empty'].src
			"
			:alt="
				n <= Math.floor(rating)
					? icons['full'].alt
					: n - 0.5 <= rating
						? icons['half'].alt
						: icons['empty'].alt
			"
		/>
	</div>
</template>

<script setup lang="ts">
import { useThemeStore } from '~/stores/themeStore';

interface Props {
	rating: number;
	widthHeight?: number;
}

const props = withDefaults(defineProps<Props>(), { widthHeight: 6 });

const ThemeStore = useThemeStore();

const icons = reactive({
	full: { src: ThemeStore.ratingIconFull.url, alt: 'Filled star rating icon' },
	half: { src: ThemeStore.ratingIconHalf.url, alt: 'Half filled star rating icon' },
	empty: { src: ThemeStore.ratingIconEmpty.url, alt: 'Unfilled star rating icon' },
});

const starSize = computed(() => {
	return `w-${props.widthHeight} h-${props.widthHeight}`;
});
</script>
